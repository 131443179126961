console.log("initdata.js loaded");

// Function to parse a query string parameter and decode it into JSON
function parseQueryString(queryString, key) {
    const params = new URLSearchParams(queryString);
    const value = params.get(key);
    console.log(`${key} String:`, value); // Log the raw string
    try {
        return JSON.parse(decodeURIComponent(value));
    } catch (error) {
        console.error(`Error parsing ${key} string:`, error);
        return null;
    }
}

// Function to send user data to the server
async function sendUserDataToServer(payload) {
    try {
        const response = await fetch('https://Follup.online/webhook/save_user_data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        if (result.error) {
            console.error('Server Error:', result.message);
        } else {
            console.log('User data saved successfully:', result);
        }
    } catch (error) {
        console.error('Error sending user data to server:', error);
    }
}

// Main execution block
if (Telegram.WebApp && Telegram.WebApp.initData) {
    const initData = Telegram.WebApp.initData;
    const platform = Telegram.WebApp.platform;

    console.log('initData:', initData); // Log initData

    try {
        // Parse initData and start_param
        const userData = parseQueryString(initData, 'user');
        const startParams = parseQueryString(initData, 'start_param');

        if (!userData) {
            throw new Error('Invalid initData or start_param format');
        }

        const userId = userData.id;
        const firstName = userData.first_name;
        const userName = userData.username;
        const lastName = userData.last_name;

        console.log('User ID:', userId, firstName);

        // Close WebApp for unauthorized users on the "web" platform
        if (platform === "web" && userId !== "2019706168" && userId !== 2019706168) {
            Telegram.WebApp.close();
        }

        // Create the payload
        const payload = {
            user_id: userId,
            first_name: firstName,
            platform: platform,
            data: initData,
            start_params: startParams,
            user_name: userName,
            last_name: lastName
        };

        console.log('Payload:', JSON.stringify(payload)); // Log payload

        // Send the payload to the server
        sendUserDataToServer(payload);
    } catch (error) {
        console.error('Error processing initData:', error);
    }
}

// Show the back button in the WebApp
window.Telegram.WebApp.BackButton.show();

// Handle back button click event
window.Telegram.WebApp.onEvent("backButtonClicked", function () {
    window.history.back(); // Use the browser history API to navigate back
});
